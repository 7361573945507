<template>
<footer class="py-4 border-t shadowm-sm">
    <div class="footer-container container-max relative">
        <!-- <img 
            :src="require('@/assets/favicon.jpeg')" 
            class="absolute left-10 top-4 w-[80px] h-auto" 
        /> -->
        <router-link to="/" class="px-[3px] md:px-[15px] md:border-r border-slate-200">Home</router-link>
        <router-link to="/contact" class="px-[3px] md:px-[15px] md:border-r border-slate-200">Get in touch</router-link>
        <router-link :to="{name: 'terms'}">Terms & conditions</router-link>
    </div>
    <div class="mx-auto w-2/3 max-w-[800px] h-[1px] my-4 bg-slate-200"></div>
    <div>
        <p class="text-center text-xs md:text-sm text-slate-500">copyright 2023&copy; pethive.app</p>
    </div>
</footer>
</template>

<style lang="scss" scoped>
.footer-container {
    @apply flex items-center justify-center gap-x-5 text-neutral-500 pb-3;
}
</style>