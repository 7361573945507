<template>
<div class="flex p-3 w-max items-center justify-between absolute z-[40] sm:hidden transition delay-500"
:class="{'hidden': openNav}">
    <img 
        :src="require('@/assets/favicon.jpeg')" 
        class="w-[40px] h-auto" 
    />
</div>
<header 
    class="w-full z-50 sticky top-0 h-auto normal-header"
>
    <i 
        v-if="width < 570"
        @click="openNav = !openNav"
        class="burger-menu pi"
        :class="[openNav? 'active pi-times':'not-active pi-bars']"
    ></i>
    <nav 
        class="container-max nav-wrapper overflow-hidden transition-all" 
        :class="[openNav && width < 570? 'h-[100vh] py-3': width < 570? 'h-0':'h-[65px]']"
    >
        <div class="nav-title p-3">
            <img 
                :src="require('@/assets/favicon.jpeg')" 
                class="w-[40px] h-auto" 
            />
            <h1 class="">PetHive</h1>
        </div>
        <div class="nav-wrapper__inner1 mb-5 sm:mb-0">
            <router-link to="/" class="nav-link logo py-2">
                <img 
                    :src="require('@/assets/favicon.jpeg')" 
                    class="w-[60px] h-auto my-2 rounded hidden sm:inline" 
                />
            </router-link>
            <router-link to="/" class="nav-link sm:hidden">Home</router-link>
            <router-link to="/about" class="nav-link">About</router-link>
            <router-link to="/contact" class="nav-link">Contact</router-link>
        </div>
       <div class="nav-wrapper__inner2">
           <router-link to="/terms-and-conditions" class="nav-link">
               Terms & Conditions
            </router-link>
            <div class="flex gap-x-3 text-slate-500 px-[15px] sm:px-0">
             <i class="pi pi-twitter social-icon"></i>
             <i class="pi pi-instagram social-icon"></i>
             <i class="pi pi-tiktok social-icon"></i>
            </div>
       </div>
    </nav>
</header>
</template>

<script setup>
/* eslit-disable */
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router';
import { useWindowSize } from '@vueuse/core'

const { width, height } = useWindowSize()
const route = useRoute()
const openNav = ref(false) 

watch(() => route, () => {
    openNav.value = false;
}, {deep: true})

watch()

</script>

<style lang="scss" scoped>
.nav-title {
    @apply text-red-800 text-2xl text-center font-extrabold sm:hidden bg-gray-100
    flex items-center gap-x-3 justify-center uppercase;
}
.burger-menu {
    @apply absolute right-[20px] text-xl transition-all cursor-pointer;

    &.active {
        @apply top-[20px] text-slate-400;
    }
    &.not-active {
        @apply top-[16px] text-slate-400;
    }
}
.fixed-header {
    @apply fixed top-0 left-0 backdrop-blur-sm bg-neutral-100 md:bg-sky-700
    border-b md:border-none
}
.normal-header {
    @apply bg-white shadow;
}
.nav-wrapper {
    @apply flex flex-col sm:flex-row sm:items-center sm:justify-between space-y-4 sm:space-y-0 sm:py-0;

    #{&}__inner1 {
        @apply flex flex-col sm:flex-row gap-x-5 sm:items-center text-neutral-800 font-medium;
    }
    #{&}__inner2 {
        @apply flex flex-col sm:flex-row gap-x-5 sm:items-center text-neutral-800 font-medium;
    }
}
.nav-link {
    @apply py-3 px-[15px] text-slate-600 hover:bg-slate-50 font-medium transition-all w-max; 

    &.logo {
        @apply hover:bg-transparent px-0;
    }
    &.router-link-active {
        @apply bg-slate-50;
    }
}

.social-icon {
    @apply p-2 hover:bg-red-800 hover:text-white transition-all;
}
</style>